<template>
  <UiComponentWrapper class="product-tour">
    <UiComponentWrapper class="container">
      <UiComponentWrapper class="headline">
        <UiComponentWrapper
          class="slider"
          :style="{ transform: `translateY(-${currentStep * 100}%)` }"
        >
          <UiComponentHeading :level="2">
            {{ data.componentFakeRoom.step1Title }}
          </UiComponentHeading>
          <UiComponentHeading :level="2">
            {{ data.componentFakeRoom.step2Title }}
          </UiComponentHeading>
          <UiComponentHeading :level="2">
            {{ data.componentFakeRoom.step3Title }}
          </UiComponentHeading>
          <UiComponentHeading :level="2">
            {{ data.componentFakeRoom.step4Title }}
          </UiComponentHeading>
          <UiComponentHeading :level="2">
            {{ data.componentFakeRoom.step5Title }}
          </UiComponentHeading>
        </UiComponentWrapper>
      </UiComponentWrapper>

      <UiComponentWrapper class="progress">
        <UiComponentWrapper
          v-for="n in 5"
          :key="`step-${n}`"
          class="step"
          :class="{
            active: currentStep === n-1,
            back: currentStep > n-1,
          }"
          @click="currentStep > n-1 ? goTo(`step-${n}`) : null"
        />
      </UiComponentWrapper>

      <UiComponentWrapper class="animation">
        <!-- Step 1 -->
        <UiComponentWrapper class="first-step input-group name">
          <UiComponentLabel id="event-name">
            {{ data.componentFakeRoom.step1EventNameLabel }}
          </UiComponentLabel>
          <UiComponentInput
            id="event-name"
            :placeholder="data.componentFakeRoom.step1EventNamePlaceholder"
            :value="eventName"
            @click="goTo('step-2')"
          />
          <UiComponentWrapper class="url">
            https://app.livestorm.co/acme/{{ eventSlug }}
            <span>{{ data.componentFakeRoom.step1EditUrl }}</span>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="first-step input-group permissions">
          <UiComponentLabel id="event-name">
            {{ data.componentFakeRoom.step1PermissionsLabel }}
          </UiComponentLabel>
          <UiComponentWrapper
            v-for="({ id, title, text }, index) in data.componentFakeRoom.step1PermissionsValues"
            :key="`radio-${id}`"
            class="radio"
            :class="`answer-${index+1}`"
          >
            <UiComponentWrapper class="state">
              <UiComponentWrapper class="checked" />
            </UiComponentWrapper>
            <UiComponentWrapper class="value">
              <UiComponentParagraph>{{ title }}</UiComponentParagraph>
              <UiComponentParagraph>{{ text }}</UiComponentParagraph>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper
          class="first-step action"
          @click="goTo('step-2')"
        >
          <UiComponentWrapper class="indicator">
            <UiComponentWrapper class="circle" />
            <UiComponentWrapper class="circle" />
          </UiComponentWrapper>
          <UiComponentWrapper class="tooltip">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
            >
              <path
                d="M1.52998 7.66935C0.331956 6.87886 0.331956 5.12114 1.52998 4.33065L6.39852 1.11825C7.72823 0.240864 9.5 1.19451 9.5 2.7876L9.5 9.21241C9.5 10.8055 7.72823 11.7591 6.39852 10.8818L1.52998 7.66935Z"
                fill="#558D9B"
              />
            </svg>
            {{ data.componentFakeRoom.step1CallToActionText }}
          </UiComponentWrapper>
        </UiComponentWrapper>

        <!-- Step 2 -->
        <UiComponentWrapper class="second-step color-picker">
          <UiComponentWrapper class="colors">
            <UiComponentWrapper
              ref="$colorsCursor"
              class="cursor"
              :style="{ left: `${colorsCursorX}px`, top: `${colorsCursorY}px` }"
            />
          </UiComponentWrapper>
          <UiComponentWrapper class="hue">
            <UiComponentWrapper
              ref="$hueCursor"
              class="cursor"
              :style="{ left: `${6+hueCursorX}px` }"
            />
          </UiComponentWrapper>
          <UiComponentWrapper class="input-group hex">
            <UiComponentWrapper
              class="color"
              :style="{ backgroundColor: hexColor }"
            />
            <UiComponentLabel id="hex">
              {{ data.componentFakeRoom.step2MainColorLabel }}
            </UiComponentLabel>
            <UiComponentInput
              v-if="colorPickerHasBeenInitialized"
              id="hex"
              ref="$input"
              :maxlength="7"
              :placeholder="hexColor"
              @input:blur="onInputBlur"
              @input:change="onInputChange"
            />
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="second-step action">
          <AtomButton
            :disabled="!hasInteractedWithColorPicker"
            @click="goTo('step-3')"
          >
            {{ data.componentFakeRoom.step2SubmitText }}
            <UiComponentIcon type="arrow-right" />
          </AtomButton>
        </UiComponentWrapper>

        <UiComponentWrapper
          class="second-step email"
          :class="{ dark: !hasEnoughContrast }"
        >
          <UiComponentWrapper
            class="hero"
            :style="{ backgroundColor: hexColor }"
          >
            <UiComponentWrapper class="organisation">
              <UiComponentImage
                v-if="data.componentFakeRoom.step2OrganisationLogo"
                :alt="data.componentFakeRoom.step2OrganisationLogo.alt"
                :expected-size="12"
                :height="data.componentFakeRoom.step2OrganisationLogo.height"
                :source="data.componentFakeRoom.step2OrganisationLogo.url"
                :width="data.componentFakeRoom.step2OrganisationLogo.width"
              />
            </UiComponentWrapper>
          </UiComponentWrapper>
          <UiComponentWrapper class="main">
            <UiComponentParagraph class="for">
              {{ data.componentFakeRoom.step2RegisterFor }}
            </UiComponentParagraph>
            <UiComponentParagraph class="title">
              {{ data.componentFakeRoom.step2EventName }}
            </UiComponentParagraph>
            <UiComponentParagraph class="date">
              {{ data.componentFakeRoom.step2EventDate }}
            </UiComponentParagraph>
            <UiComponentWrapper class="message">
              {{ data.componentFakeRoom.step2EventInvitationMessage }}
            </UiComponentWrapper>
            <svg
              class="shape"
              fill="none"
              height="5"
              viewBox="0 0 12 5"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.52129 4.26374L11.2073 0.577751L0.726314 0.0537109L4.488 4.19156C5.29308 5.07715 6.67499 5.11004 7.52129 4.26374Z"
                fill="#F6F7F9"
              />
            </svg>
            <UiComponentWrapper class="author">
              <UiComponentImage
                alt="William Johnson"
                class="avatar"
                :expected-size="22"
                :height="360"
                source="https://livestorm.imgix.net/1127/1663600193-williamjohnson.jpg"
                :width="360"
              />
              <UiComponentParagraph>William Johnson</UiComponentParagraph>
            </UiComponentWrapper>
            <AtomButton :style="{ backgroundColor: hexColor }">
              {{ data.componentFakeRoom.step2RegisterCallToActionText }}
            </AtomButton>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper
          class="second-step registration-page"
          :class="{ dark: !hasEnoughContrast }"
        >
          <UiComponentWrapper class="hero">
            <UiComponentWrapper class="cover">
              <UiComponentImage
                v-if="data.componentFakeRoom.step2EventBackgroundImage"
                :alt="data.componentFakeRoom.step2EventBackgroundImage.alt"
                class="background"
                :expected-size="634"
                :height="data.componentFakeRoom.step2EventBackgroundImage.height"
                :source="data.componentFakeRoom.step2EventBackgroundImage.url"
                :width="data.componentFakeRoom.step2EventBackgroundImage.width"
              />
            </UiComponentWrapper>
            <UiComponentWrapper
              class="layer"
              :style="{ backgroundColor: hexColor }"
            />
            <UiComponentWrapper class="content">
              <UiComponentParagraph class="invited-by">
                {{ data.componentFakeRoom.step2InvitedBy }}
              </UiComponentParagraph>
              <UiComponentWrapper class="organisation">
                <UiComponentImage
                  v-if="data.componentFakeRoom.step2OrganisationLogo"
                  :alt="data.componentFakeRoom.step2OrganisationLogo.alt"
                  :expected-size="18"
                  :height="data.componentFakeRoom.step2OrganisationLogo.height"
                  :source="data.componentFakeRoom.step2OrganisationLogo.url"
                  :width="data.componentFakeRoom.step2OrganisationLogo.width"
                />
              </UiComponentWrapper>
              <UiComponentParagraph class="title">
                {{ data.componentFakeRoom.step2EventName }}
              </UiComponentParagraph>
              <UiComponentWrapper class="date">
                <UiComponentParagraph>{{ data.componentFakeRoom.step2EventDate }}</UiComponentParagraph>
                <UiComponentIcon type="chevron-down" />
              </UiComponentWrapper>
              <UiComponentWrapper class="countdown">
                <UiComponentWrapper class="item">
                  <UiComponentParagraph class="number">
                    0
                  </UiComponentParagraph>
                  <UiComponentParagraph class="unit">
                    {{ data.componentFakeRoom.step2CountdownDays }}
                  </UiComponentParagraph>
                </UiComponentWrapper>
                <UiComponentWrapper class="item">
                  <UiComponentParagraph class="number">
                    2
                  </UiComponentParagraph>
                  <UiComponentParagraph class="unit">
                    {{ data.componentFakeRoom.step2CountdownHours }}
                  </UiComponentParagraph>
                </UiComponentWrapper>
                <UiComponentWrapper class="item">
                  <UiComponentParagraph class="number">
                    5
                  </UiComponentParagraph>
                  <UiComponentParagraph class="unit">
                    {{ data.componentFakeRoom.step2CountdownMinutes }}
                  </UiComponentParagraph>
                </UiComponentWrapper>
                <UiComponentWrapper class="item">
                  <UiComponentParagraph class="number">
                    56
                  </UiComponentParagraph>
                  <UiComponentParagraph class="unit">
                    {{ data.componentFakeRoom.step2CountdownSeconds }}
                  </UiComponentParagraph>
                </UiComponentWrapper>
              </UiComponentWrapper>
              <UiComponentWrapper class="register">
                <UiComponentInput :placeholder="data.componentFakeRoom.step2EmailPlaceholder" />
                <AtomButton type="secondary">
                  {{ data.componentFakeRoom.step2RegisterCallToActionText }}
                </AtomButton>
              </UiComponentWrapper>
              <UiComponentParagraph class="login">
                {{ data.componentFakeRoom.step2AlreadyRegistered }}
              </UiComponentParagraph>
            </UiComponentWrapper>
          </UiComponentWrapper>
          <UiComponentWrapper class="main">
            <UiComponentWrapper class="info">
              <UiComponentWrapper class="item">
                <UiComponentIcon type="calendar" />
                <UiComponentParagraph>{{ data.componentFakeRoom.step2EventDate }}</UiComponentParagraph>
              </UiComponentWrapper>
              <UiComponentWrapper class="item">
                <UiComponentIcon type="clock" />
                <UiComponentParagraph>{{ data.componentFakeRoom.step2EventDuration }}</UiComponentParagraph>
              </UiComponentWrapper>
            </UiComponentWrapper>
            <UiComponentWrapper class="about">
              <UiComponentParagraph>{{ data.componentFakeRoom.step2AboutThisEvent }}</UiComponentParagraph>
              <UiComponentWrapper class="placeholder">
                <UiComponentWrapper class="line" />
                <UiComponentWrapper class="line" />
              </UiComponentWrapper>
              <UiComponentWrapper class="placeholder">
                <UiComponentWrapper class="line" />
                <UiComponentWrapper class="line" />
                <UiComponentWrapper class="line" />
                <UiComponentWrapper class="line" />
                <UiComponentWrapper class="line" />
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="second-step first-advice">
          <UiComponentWrapper class="tooltip">
            {{ data.componentFakeRoom.step2FirstCallToActionText }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
            >
              <path
                d="M7.66935 8.47002C6.87886 9.66804 5.12114 9.66805 4.33065 8.47003L1.11825 3.60149C0.240863 2.27177 1.1945 0.5 2.7876 0.5L9.21241 0.499999C10.8055 0.499999 11.7591 2.27177 10.8818 3.60149L7.66935 8.47002Z"
                fill="#558D9B"
              />
            </svg>
          </UiComponentWrapper>
          <UiComponentWrapper class="indicator">
            <UiComponentWrapper class="circle" />
            <UiComponentWrapper class="circle" />
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="second-step last-advice">
          <UiComponentWrapper class="indicator">
            <UiComponentWrapper class="circle" />
            <UiComponentWrapper class="circle" />
          </UiComponentWrapper>
          <UiComponentWrapper class="tooltip">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="12"
              viewBox="0 0 10 12"
              fill="none"
            >
              <path
                d="M1.52998 7.66935C0.331956 6.87886 0.331956 5.12114 1.52998 4.33065L6.39852 1.11825C7.72823 0.240864 9.5 1.19451 9.5 2.7876L9.5 9.21241C9.5 10.8055 7.72823 11.7591 6.39852 10.8818L1.52998 7.66935Z"
                fill="#558D9B"
              />
            </svg>
            {{ data.componentFakeRoom.step2LastCallToActionText }}
          </UiComponentWrapper>
        </UiComponentWrapper>

        <!-- Step 3 -->
        <UiComponentWrapper
          class="third-step background"
          :style="{ backgroundColor: hexColor }"
        />

        <UiComponentWrapper
          class="third-step metadata"
          :class="{ dark: !hasEnoughContrast }"
          :style="{ '--filter': hexColorAsCssFilter }"
        >
          <UiComponentWrapper class="organisation">
            <UiComponentImage
              v-if="data.componentFakeRoom.step2OrganisationLogo"
              :alt="data.componentFakeRoom.step2OrganisationLogo.alt"
              :expected-size="12"
              :height="data.componentFakeRoom.step2OrganisationLogo.height"
              :source="data.componentFakeRoom.step2OrganisationLogo.url"
              :width="data.componentFakeRoom.step2OrganisationLogo.width"
            />
          </UiComponentWrapper>
          <UiComponentWrapper class="event">
            <UiComponentParagraph>
              {{ data.componentFakeRoom.step2EventName }}
              <span class="live">{{ data.componentFakeRoom.step3Live }}</span>
            </UiComponentParagraph>
            <UiComponentParagraph>{{ data.componentFakeRoom.step2EventDate }}</UiComponentParagraph>
          </UiComponentWrapper>
          <UiComponentWrapper class="started-at">
            <UiComponentParagraph>{{ data.componentFakeRoom.step3StartedAt }}</UiComponentParagraph>
          </UiComponentWrapper>
          <UiComponentWrapper class="action">
            <AtomButton @click="goTo('step-4')">
              {{ data.componentFakeRoom.step3EndEvent }}
            </AtomButton>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="third-step streams">
          <UiComponentWrapper class="primary">
            <UiComponentVideo
              :height="276"
              loop
              muted
              url="https://livestorm.imgix.net/1127/1691677667-william-johnson-stream-talk.mp4"
              :width="582"
            />
            <UiComponentWrapper class="user">
              <UiComponentImage
                alt="William Johnson"
                :expected-size="10"
                :height="360"
                source="https://livestorm.imgix.net/1127/1691679115-william-johnson-stream-talk.mp4"
                :width="360"
              />
              <UiComponentParagraph>William Johnson</UiComponentParagraph>
            </UiComponentWrapper>
          </UiComponentWrapper>
          <UiComponentWrapper class="secondaries">
            <UiComponentWrapper class="stream">
              <UiComponentVideo
                :height="80"
                loop
                muted
                url="https://livestorm.imgix.net/1127/1691679065-daniel-smith-stream-talk-jpg.mp4"
                :width="126"
              />
              <UiComponentWrapper class="user">
                <UiComponentImage
                  alt="Daniel Smith"
                  :expected-size="10"
                  :height="360"
                  source="https://livestorm.imgix.net/1127/1663600054-danielsmith.jpg"
                  :width="360"
                />
                <UiComponentParagraph>Daniel Smith</UiComponentParagraph>
              </UiComponentWrapper>
            </UiComponentWrapper>
            <UiComponentWrapper class="stream">
              <UiComponentVideo
                :height="80"
                loop
                muted
                url="https://livestorm.imgix.net/1127/1691679092-nathalie-thomas-stream-talk.mp4"
                :width="126"
              />
              <UiComponentWrapper class="user">
                <UiComponentImage
                  alt="Nathalie Thomas"
                  :expected-size="10"
                  :height="360"
                  source="https://livestorm.imgix.net/1127/1663600156-nathaliethomas.jpg"
                  :width="360"
                />
                <UiComponentParagraph>Nathalie Thomas</UiComponentParagraph>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
          <UiComponentWrapper class="actions">
            <UiComponentWrapper class="item">
              <UiComponentImage
                v-if="data.componentFakeRoom.step3MicroIcon"
                :alt="data.componentFakeRoom.step3MicroIcon.alt"
                :expected-size="10"
                :height="data.componentFakeRoom.step3MicroIcon.height"
                :source="data.componentFakeRoom.step3MicroIcon.url"
                :width="data.componentFakeRoom.step3MicroIcon.width"
              />
              <UiComponentParagraph>{{ data.componentFakeRoom.step3MicroTitle }}</UiComponentParagraph>
            </UiComponentWrapper>
            <UiComponentWrapper class="item">
              <UiComponentImage
                v-if="data.componentFakeRoom.step3CameraIcon"
                :alt="data.componentFakeRoom.step3CameraIcon.alt"
                :expected-size="10"
                :height="data.componentFakeRoom.step3CameraIcon.height"
                :source="data.componentFakeRoom.step3CameraIcon.url"
                :width="data.componentFakeRoom.step3CameraIcon.width"
              />
              <UiComponentParagraph>{{ data.componentFakeRoom.step3CameraTitle }}</UiComponentParagraph>
            </UiComponentWrapper>
            <UiComponentWrapper class="item">
              <UiComponentImage
                v-if="data.componentFakeRoom.step3ShareIcon"
                :alt="data.componentFakeRoom.step3ShareIcon.alt"
                :expected-size="10"
                :height="data.componentFakeRoom.step3ShareIcon.height"
                :source="data.componentFakeRoom.step3ShareIcon.url"
                :width="data.componentFakeRoom.step3ShareIcon.width"
              />
              <UiComponentParagraph>{{ data.componentFakeRoom.step3ShareTitle }}</UiComponentParagraph>
            </UiComponentWrapper>
            <UiComponentWrapper class="item">
              <UiComponentImage
                v-if="data.componentFakeRoom.step3RecordIcon"
                :alt="data.componentFakeRoom.step3RecordIcon.alt"
                :expected-size="10"
                :height="data.componentFakeRoom.step3RecordIcon.height"
                :source="data.componentFakeRoom.step3RecordIcon.url"
                :width="data.componentFakeRoom.step3RecordIcon.width"
              />
              <UiComponentParagraph>{{ data.componentFakeRoom.step3RecordTitle }}</UiComponentParagraph>
            </UiComponentWrapper>
            <UiComponentWrapper class="item">
              <UiComponentImage
                v-if="data.componentFakeRoom.step3ReactIcon"
                :alt="data.componentFakeRoom.step3ReactIcon.alt"
                :expected-size="10"
                :height="data.componentFakeRoom.step3ReactIcon.height"
                :source="data.componentFakeRoom.step3ReactIcon.url"
                :width="data.componentFakeRoom.step3ReactIcon.width"
              />
              <UiComponentParagraph>{{ data.componentFakeRoom.step3ReactTitle }}</UiComponentParagraph>
            </UiComponentWrapper>
            <UiComponentWrapper class="item">
              <UiComponentImage
                v-if="data.componentFakeRoom.step3LayoutIcon"
                :alt="data.componentFakeRoom.step3LayoutIcon.alt"
                :expected-size="10"
                :height="data.componentFakeRoom.step3LayoutIcon.height"
                :source="data.componentFakeRoom.step3LayoutIcon.url"
                :width="data.componentFakeRoom.step3LayoutIcon.width"
              />
              <UiComponentParagraph>{{ data.componentFakeRoom.step3LayoutTitle }}</UiComponentParagraph>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper
          class="third-step sidebar"
          :class="{ chat: chatTabIsActive, polls: pollsTabIsActive }"
        >
          <UiComponentWrapper class="head">
            <UiComponentImage
              v-if="sidebarIcon"
              :key="chatTabIsActive ? 'chat' : 'poll'"
              :alt="sidebarIcon.alt"
              :expected-size="9"
              :height="sidebarIcon.height"
              :source="sidebarIcon.url"
              :width="sidebarIcon.width"
            />
            <UiComponentParagraph>{{ sidebarTitle }}</UiComponentParagraph>
            <UiComponentIcon type="x" />
          </UiComponentWrapper>
          <UiComponentWrapper class="body">
            <UiComponentWrapper class="slider">
              <UiComponentWrapper class="view messages">
                <UiComponentWrapper class="message">
                  <UiComponentImage
                    alt="Barbara Sheffield"
                    class="avatar"
                    :expected-size="22"
                    :height="360"
                    source="https://livestorm.imgix.net/1127/1663600020-barbarasheffield.jpg"
                    :width="360"
                  />
                  <UiComponentWrapper class="content">
                    <UiComponentParagraph>Barbara Sheffield</UiComponentParagraph>
                    <UiComponentParagraph>Thank you for hosting this online event!</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>

                <UiComponentWrapper class="message">
                  <UiComponentImage
                    alt="James Allen"
                    class="avatar"
                    :expected-size="22"
                    :height="360"
                    source="https://livestorm.imgix.net/1127/1663600083-jamesallen.jpg"
                    :width="360"
                  />
                  <UiComponentWrapper class="content">
                    <UiComponentParagraph>James Allen</UiComponentParagraph>
                    <UiComponentParagraph>When is your next online event?</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>

                <UiComponentWrapper class="message">
                  <UiComponentImage
                    alt="Scott Johnson"
                    class="avatar"
                    :expected-size="22"
                    :height="360"
                    source="https://livestorm.imgix.net/1127/1663600178-scottjohnson.jpg"
                    :width="360"
                  />
                  <UiComponentWrapper class="content">
                    <UiComponentParagraph>Scott Johnson</UiComponentParagraph>
                    <UiComponentParagraph>We're running a weekly event series. 🙂</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>

                <UiComponentWrapper class="message">
                  <UiComponentImage
                    alt="Maggie Cochran"
                    class="avatar"
                    :expected-size="22"
                    :height="360"
                    source="https://livestorm.imgix.net/1127/1663600124-maggiecochran.jpg"
                    :width="360"
                  />
                  <UiComponentWrapper class="content">
                    <UiComponentParagraph>Maggie Cochran</UiComponentParagraph>
                    <UiComponentParagraph>Will you send the recording afterwards?</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>

                <UiComponentWrapper class="message">
                  <UiComponentImage
                    alt="Jennifer Herbert"
                    class="avatar"
                    :expected-size="22"
                    :height="360"
                    source="https://livestorm.imgix.net/1127/1663600093-jenniferherbert.jpg"
                    :width="360"
                  />
                  <UiComponentWrapper class="content">
                    <UiComponentParagraph>Jennifer Herbert</UiComponentParagraph>
                    <UiComponentParagraph>Yes, of course, you'll receive it automatically after the event.</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>

                <UiComponentWrapper class="message">
                  <UiComponentImage
                    alt="Jonas Housman"
                    class="avatar"
                    :expected-size="22"
                    :height="360"
                    source="https://livestorm.imgix.net/1127/1663600096-jonashousman.jpg"
                    :width="360"
                  />
                  <UiComponentWrapper class="content">
                    <UiComponentParagraph>Jonas Housman</UiComponentParagraph>
                    <UiComponentParagraph>Thank you for your answer during the Q&A.</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>

                <UiComponentWrapper class="message">
                  <UiComponentImage
                    alt="Louise Jones"
                    class="avatar"
                    :expected-size="22"
                    :height="360"
                    source="https://livestorm.imgix.net/1127/1663600118-louisejones.jpg"
                    :width="360"
                  />
                  <UiComponentWrapper class="content">
                    <UiComponentParagraph>Louise Jones</UiComponentParagraph>
                    <UiComponentParagraph>Very interesting topic and well organized! </UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>
              </UiComponentWrapper>

              <UiComponentWrapper class="view poll">
                <UiComponentParagraph class="question">
                  {{ data.componentFakeRoom.step3PollsQuestion }}
                </UiComponentParagraph>
                <UiComponentWrapper class="answers">
                  <UiComponentWrapper class="answer">
                    <UiComponentParagraph>{{ data.componentFakeRoom.step3PollsAnswer1 }}</UiComponentParagraph>
                    <UiComponentParagraph>0%</UiComponentParagraph>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="answer">
                    <UiComponentParagraph>{{ data.componentFakeRoom.step3PollsAnswer2 }}</UiComponentParagraph>
                    <UiComponentParagraph>0%</UiComponentParagraph>
                  </UiComponentWrapper>
                  <UiComponentWrapper class="answer">
                    <UiComponentParagraph>{{ data.componentFakeRoom.step3PollsAnswer3 }}</UiComponentParagraph>
                    <UiComponentParagraph>0%</UiComponentParagraph>
                  </UiComponentWrapper>
                </UiComponentWrapper>
                <UiComponentWrapper class="results">
                  <AtomButton type="secondary">
                    {{ data.componentFakeRoom.step3PollsCallToActionText }}
                  </AtomButton>
                  <UiComponentParagraph>{{ data.componentFakeRoom.step3PollsVotesCount }}</UiComponentParagraph>
                </UiComponentWrapper>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
          <UiComponentWrapper class="footer">
            <UiComponentImage
              v-if="data.componentFakeRoom.step3ChatEmojiReactionIcon"
              :alt="data.componentFakeRoom.step3ChatEmojiReactionIcon.alt"
              :expected-size="9"
              :height="data.componentFakeRoom.step3ChatEmojiReactionIcon.height"
              :source="data.componentFakeRoom.step3ChatEmojiReactionIcon.url"
              :width="data.componentFakeRoom.step3ChatEmojiReactionIcon.width"
            />
            <UiComponentParagraph>{{ data.componentFakeRoom.step3ChatPlaceholder }}</UiComponentParagraph>
            <UiComponentImage
              v-if="data.componentFakeRoom.step3ChatSendIcon"
              :alt="data.componentFakeRoom.step3ChatSendIcon.alt"
              :expected-size="9"
              :height="data.componentFakeRoom.step3ChatSendIcon.height"
              :source="data.componentFakeRoom.step3ChatSendIcon.url"
              :width="data.componentFakeRoom.step3ChatSendIcon.width"
            />
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper
          class="third-step nav"
          :style="{ '--color': hexColor, '--filter': hexColorAsCssFilter }"
        >
          <UiComponentWrapper class="item">
            <UiComponentImage
              v-if="data.componentFakeRoom.step3AppsIcon"
              :alt="data.componentFakeRoom.step3AppsIcon.alt"
              :expected-size="10"
              :height="data.componentFakeRoom.step3AppsIcon.height"
              :source="data.componentFakeRoom.step3AppsIcon.url"
              :width="data.componentFakeRoom.step3AppsIcon.width"
            />
            <UiComponentParagraph>{{ data.componentFakeRoom.step3AppsTitle }}</UiComponentParagraph>
          </UiComponentWrapper>
          <UiComponentWrapper
            class="item"
            :class="{ active: pollsTabIsActive }"
          >
            <UiComponentImage
              v-if="data.componentFakeRoom.step3PollsIcon"
              :alt="data.componentFakeRoom.step3PollsIcon.alt"
              :expected-size="10"
              :height="data.componentFakeRoom.step3PollsIcon.height"
              :source="data.componentFakeRoom.step3PollsIcon.url"
              :width="data.componentFakeRoom.step3PollsIcon.width"
            />
            <UiComponentParagraph>{{ data.componentFakeRoom.step3PollsTitle }}</UiComponentParagraph>
          </UiComponentWrapper>
          <UiComponentWrapper class="item">
            <UiComponentImage
              v-if="data.componentFakeRoom.step3QuestionsIcon"
              :alt="data.componentFakeRoom.step3QuestionsIcon.alt"
              :expected-size="10"
              :height="data.componentFakeRoom.step3QuestionsIcon.height"
              :source="data.componentFakeRoom.step3QuestionsIcon.url"
              :width="data.componentFakeRoom.step3QuestionsIcon.width"
            />
            <UiComponentParagraph>{{ data.componentFakeRoom.step3QuestionsTitle }}</UiComponentParagraph>
          </UiComponentWrapper>
          <UiComponentWrapper
            class="item"
            :class="{ active: chatTabIsActive }"
          >
            <UiComponentImage
              v-if="data.componentFakeRoom.step3ChatIcon"
              :alt="data.componentFakeRoom.step3ChatIcon.alt"
              :expected-size="10"
              :height="data.componentFakeRoom.step3ChatIcon.height"
              :source="data.componentFakeRoom.step3ChatIcon.url"
              :width="data.componentFakeRoom.step3ChatIcon.width"
            />
            <UiComponentParagraph>{{ data.componentFakeRoom.step3ChatTitle }}</UiComponentParagraph>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="third-step advice">
          <UiComponentWrapper class="tooltip">
            {{ data.componentFakeRoom.step3CallToActionText }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="12"
              viewBox="0 0 9 12"
              fill="none"
            >
              <path
                d="M7.97002 4.33065C9.16804 5.12114 9.16804 6.87886 7.97002 7.66935L3.10148 10.8818C1.77177 11.7591 -8.0097e-07 10.8055 -8.19968e-07 9.2124L-8.96583e-07 2.78759C-9.1558e-07 1.1945 1.77177 0.240863 3.10148 1.11825L7.97002 4.33065Z"
                fill="#558D9B"
              />
            </svg>
          </UiComponentWrapper>
          <UiComponentWrapper class="indicator">
            <UiComponentWrapper class="circle" />
            <UiComponentWrapper class="circle" />
          </UiComponentWrapper>
        </UiComponentWrapper>

        <!-- Step 4 -->
        <UiComponentWrapper class="fourth-step stats">
          <UiComponentWrapper class="funnel">
            <UiComponentParagraph class="title">
              {{ data.componentFakeRoom.step4AttendeesFunnel }}
            </UiComponentParagraph>
            <UiComponentWrapper class="graph">
              <UiComponentWrapper class="registered">
                <UiComponentParagraph>{{ data.componentFakeRoom.step4RegisteredCount }}</UiComponentParagraph>
              </UiComponentWrapper>
              <UiComponentWrapper class="attended">
                <UiComponentParagraph>{{ data.componentFakeRoom.step4AttendedCount }}</UiComponentParagraph>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
          <UiComponentWrapper class="progression">
            <UiComponentParagraph class="title">
              {{ data.componentFakeRoom.step4ProgressionOfConnectedAttendeesDuringTheEvent }}
            </UiComponentParagraph>
            <UiComponentWrapper class="graph">
              <UiComponentWrapper class="x-axis">
                <UiComponentParagraph
                  v-for="n in 7"
                  :key="`x-${n}`"
                >
                  {{ 10*(n-1) }} min
                </UiComponentParagraph>
              </UiComponentWrapper>
              <UiComponentWrapper class="y-axis">
                <UiComponentParagraph
                  v-for="n in 2"
                  :key="`y-${n}`"
                >
                  {{ 200*(2-n) }}
                </UiComponentParagraph>
              </UiComponentWrapper>
              <svg>
                <path
                  :d="backgroundPathData"
                  fill="#F0F4FF"
                />
                <path
                  :d="linePathData"
                  fill="none"
                  stroke="#05299E"
                  stroke-width="1"
                />
                <circle
                  v-for="({ radius, x, y }, index) in circles"
                  :key="`circle-${index}`"
                  :cx="x"
                  :cy="y"
                  :r="radius"
                  fill="#FFFFFF"
                  stroke="#05299E"
                  stroke-width="1"
                />
              </svg>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="fourth-step attendees">
          <UiComponentWrapper class="head">
            <UiComponentParagraph>{{ data.componentFakeRoom.step4AvatarColumn }}</UiComponentParagraph>
            <UiComponentParagraph>{{ data.componentFakeRoom.step4NameColumn }}</UiComponentParagraph>
            <UiComponentParagraph>{{ data.componentFakeRoom.step4EmailColumn }}</UiComponentParagraph>
            <UiComponentParagraph>{{ data.componentFakeRoom.step4RegistrationDateColumn }}</UiComponentParagraph>
            <UiComponentParagraph>{{ data.componentFakeRoom.step4AttendedColumn }}</UiComponentParagraph>
            <UiComponentParagraph>{{ data.componentFakeRoom.step4AttendanceRateColumn }}</UiComponentParagraph>
            <UiComponentParagraph>{{ data.componentFakeRoom.step4AttendanceDurationColumn }}</UiComponentParagraph>
          </UiComponentWrapper>
          <UiComponentWrapper class="body">
            <UiComponentWrapper
              v-for="attendee in data.componentFakeRoom.step4RegisteredPeople"
              :key="attendee.id"
              class="row"
            >
              <UiComponentWrapper class="avatar">
                <UiComponentImage
                  v-if="attendee.avatar"
                  :alt="attendee.avatar.alt"
                  :expected-size="17"
                  :height="attendee.avatar.height"
                  :source="attendee.avatar.url"
                  :width="attendee.avatar.width"
                />
              </UiComponentWrapper>
              <UiComponentParagraph class="name">
                {{ attendee.name }}
              </UiComponentParagraph>
              <UiComponentParagraph class="email">
                {{ attendee.email }}
              </UiComponentParagraph>
              <UiComponentParagraph class="registration-date">
                {{ attendee.registrationDate }}
              </UiComponentParagraph>
              <UiComponentWrapper class="attended">
                <UiComponentIcon :type="attendee.attended ? 'check' : 'x'" />
              </UiComponentWrapper>
              <UiComponentParagraph class="attendance-rate">
                {{ attendee.attendanceRate }}
              </UiComponentParagraph>
              <UiComponentParagraph class="attendance-duration">
                {{ attendee.attendanceDuration }}
              </UiComponentParagraph>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="fourth-step shadow" />
        <UiComponentWrapper class="fourth-step profile">
          <UiComponentWrapper class="inner">
            <UiComponentWrapper class="avatar">
              <UiComponentImage
                :alt="data.componentFakeRoom.step4FirstNameValue"
                :expected-size="35"
                :height="360"
                source="https://livestorm.imgix.net/1127/1663600029-candicegonzales.jpg"
                :width="360"
              />
            </UiComponentWrapper>
            <UiComponentParagraph class="title">
              {{ data.componentFakeRoom.step4RegistrationFormInformationGroup }}
            </UiComponentParagraph>
            <UiComponentParagraph class="label">
              {{ data.componentFakeRoom.step4EmailLabel }}
            </UiComponentParagraph>
            <UiComponentParagraph class="value email">
              {{ data.componentFakeRoom.step4EmailValue }}
            </UiComponentParagraph>
            <UiComponentParagraph class="label">
              {{ data.componentFakeRoom.step4FirstNameLabel }}
            </UiComponentParagraph>
            <UiComponentParagraph class="value">
              {{ data.componentFakeRoom.step4FirstNameValue }}
            </UiComponentParagraph>
            <UiComponentParagraph class="label">
              {{ data.componentFakeRoom.step4LastNameLabel }}
            </UiComponentParagraph>
            <UiComponentParagraph class="value">
              {{ data.componentFakeRoom.step4LastNameValue }}
            </UiComponentParagraph>
            <UiComponentParagraph class="title">
              {{ data.componentFakeRoom.step4RegistrationAndAttendanceGroup }}
            </UiComponentParagraph>
            <UiComponentParagraph class="label">
              {{ data.componentFakeRoom.step4RegistrationDateLabel }}
            </UiComponentParagraph>
            <UiComponentParagraph class="value">
              {{ data.componentFakeRoom.step4RegistrationDateValue }}
            </UiComponentParagraph>
            <UiComponentParagraph class="label">
              {{ data.componentFakeRoom.step4NumberOfSessionRegisteredLabel }}
            </UiComponentParagraph>
            <UiComponentParagraph class="value">
              {{ data.componentFakeRoom.step4NumberOfSessionRegisteredValue }}
            </UiComponentParagraph>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="fourth-step action">
          <AtomButton @click="goTo('step-5')">
            {{ data.componentFakeRoom.step4ButtonText }}
            <UiComponentIcon type="arrow-right" />
          </AtomButton>
        </UiComponentWrapper>

        <UiComponentWrapper class="fourth-step advice">
          <UiComponentWrapper class="tooltip">
            {{ data.componentFakeRoom.step4CallToActionText }}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
            >
              <path
                d="M7.66935 8.47002C6.87886 9.66804 5.12114 9.66805 4.33065 8.47003L1.11825 3.60149C0.240863 2.27177 1.1945 0.5 2.7876 0.5L9.21241 0.499999C10.8055 0.499999 11.7591 2.27177 10.8818 3.60149L7.66935 8.47002Z"
                fill="#558D9B"
              />
            </svg>
          </UiComponentWrapper>
          <UiComponentWrapper class="indicator">
            <UiComponentWrapper class="circle" />
            <UiComponentWrapper class="circle" />
          </UiComponentWrapper>
        </UiComponentWrapper>

        <!-- Step 5 -->
        <UiComponentWrapper class="fifth-step apps">
          <UiComponentParagraph class="title">
            {{ data.componentFakeRoom.step5AppsForThisEvent }}
          </UiComponentParagraph>
          <UiComponentWrapper class="table">
            <UiComponentWrapper class="head">
              <UiComponentParagraph class="name">
                {{ data.componentFakeRoom.step5NameColumn }}
              </UiComponentParagraph>
              <UiComponentParagraph class="enabled">
                {{ data.componentFakeRoom.step5EnabledColumn }}
              </UiComponentParagraph>
            </UiComponentWrapper>
            <UiComponentWrapper class="body">
              <UiComponentWrapper
                v-for="app in data.componentFakeRoom.step5Apps"
                :key="`app-${app.id}`"
                class="row"
              >
                <UiComponentImage
                  v-if="app.logo"
                  :alt="app.logo.alt"
                  class="logo"
                  :expected-size="24"
                  :height="app.logo.height"
                  :source="app.logo.url"
                  :width="app.logo.width"
                />
                <UiComponentParagraph class="name">
                  {{ app.name }}
                </UiComponentParagraph>
                <UiComponentWrapper
                  class="enabled"
                  :class="{ active: app.enabled }"
                >
                  <UiComponentIcon
                    v-if="app.enabled"
                    type="check"
                  />
                </UiComponentWrapper>
              </UiComponentWrapper>
            </UiComponentWrapper>
          </UiComponentWrapper>
        </UiComponentWrapper>

        <UiComponentWrapper class="fifth-step call-to-action">
          <UiComponentHeading :level="4">
            {{ data.componentFakeRoom.step5Headline }}
          </UiComponentHeading>
          <AtomButton
            :action="signupCallToAction.action"
            size="l"
            :to="signupCallToAction.to"
          >
            {{ signupCallToAction.slot }}
          </AtomButton>
          <AtomButton
            :action="requestDemoCallToAction.action"
            size="l"
            type="secondary"
            :to="requestDemoCallToAction.to"
          >
            {{ requestDemoCallToAction.slot }}
          </AtomButton>
        </UiComponentWrapper>

        <UiComponentWrapper class="fifth-step filter" />
      </UiComponentWrapper>
    </UiComponentWrapper>
  </UiComponentWrapper>
</template>

<script setup>
import { gsap } from 'gsap'

// Composables
const { state } = useConditionalPreviewMode()
const { locale } = useI18n()
const { $slugify } = useNuxtApp()
const { public: publicRuntimeConfig } = useRuntimeConfig()

// Async Data
const { data } = await useFetch(`/api/content/components/room/${locale.value}`, {
  key: `component-room-${locale.value}`,
  query: {
    preview: state.preview,
  },
})

// Ref
const $colorsCursor = ref(null)
const $hueCursor = ref(null)
const $input = ref(null)
const chatTabIsActive = ref(true)
const colorPickerHasBeenInitialized = ref(false)
const colorsContext = ref(null)
const colorsCursorIsDraggable = ref(false)
const colorsCursorX = ref(120)
const colorsCursorY = ref(120)
const currentStep = ref(0)
const eventName = ref('')
const eventSlug = ref('')
const framesPerSecond = ref(25)
const graphHeight = ref(67)
const graphValues = ref([
  { value: 0.71 },
  { value: 0.78 },
  { value: 0.79 },
  { value: 0.94 },
  { value: 0.78 },
  { value: 0.77 },
  { value: 0.78 },
  { value: 0.79 },
  { value: 0.8 },
  { value: 0.92 },
  { value: 0.79 },
  { value: 0.74 },
  { value: 0.67 },
])
const graphWidth = ref(488)
const hasInteractedWithColorPicker = ref(false)
const hexColor = ref('#994D4B')
const hueContext = ref(null)
const hueCursorIsDraggable = ref(false)
const hueCursorX = ref(0)
const playheadIsMoving = ref(false)
const pollsTabIsActive = ref(false)

// Variables
let timeline = null

// Computed Properties
const backgroundPathData = computed(() => {
  const coordinates = []
  coordinates.push(`M 3 ${graphHeight.value}`)
  coordinates.push(
    ...graphValues.value.map(({ value }, index) => {
      const x = 3 + Math.round(((graphWidth.value - 6) / (graphValues.value.length - 1)) * index)
      const y = graphHeight.value - Math.round(graphHeight.value * value)
      return `L ${x} ${y}`
    }),
  )
  coordinates.push(`L ${graphWidth.value - 3} ${graphHeight.value}`)
  coordinates.push('Z')
  return coordinates.join(' ')
})

const circles = computed(() => {
  return [
    ...graphValues.value.map(({ value }, index) => {
      const radius = 3
      const x = 3 + Math.round(((graphWidth.value - 6) / (graphValues.value.length - 1)) * index)
      const y = graphHeight.value - Math.round(graphHeight.value * value)
      return {
        radius,
        x,
        y,
      }
    }),
  ]
})

const hexColorAsCssFilter = computed(() => {
  if (!colorPickerHasBeenInitialized.value) {
    return ''
  }

  const { red, green, blue } = hexToRgb(hexColor.value)
  const color = new Color(red, green, blue)
  const solver = new Solver(color)
  const result = solver.solve()

  return result.filter
})

const hueColor = computed(() => {
  if (hueContext.value === null) {
    return null
  }

  const { data } = hueContext.value.getImageData(hueCursorX.value, 6, 1, 1)
  const [red, green, blue] = data

  return rgbToHex({ red, green, blue }).toUpperCase()
})

const hasEnoughContrast = computed(() => {
  const foreground = getContrastDiff(hexToRgb(hexColor.value))
  const darkBackground = Math.abs(
    foreground
    - getContrastDiff({
      red: 18,
      green: 38,
      blue: 43,
    }),
  )
  const lightBackground = Math.abs(
    foreground
    - getContrastDiff({
      red: 246,
      green: 248,
      blue: 249,
    }),
  )
  return lightBackground >= darkBackground
})

const linePathData = computed(() => {
  return [
    ...graphValues.value.map(({ value }, index) => {
      const command = index === 0 ? 'M' : 'L'
      const x = 3 + Math.round(((graphWidth.value - 6) / (graphValues.value.length - 1)) * index)
      const y = graphHeight.value - Math.round(graphHeight.value * value)
      return `${command} ${x} ${y}`
    }),
  ].join(' ')
})

const requestDemoCallToAction = computed(() => {
  return {
    action: 'Demo clicked',
    slot: data.value.componentGlobal.ctaGetDemo,
    to: '#form-demo',
  }
})

const sidebarIcon = computed(() => {
  return chatTabIsActive.value
    ? data.value.componentFakeRoom.step3ChatIconAlternate
    : data.value.componentFakeRoom.step3PollsIconAlternate
})

const sidebarTitle = computed(() => {
  return chatTabIsActive.value
    ? data.value.componentFakeRoom.step3ChatTitle
    : data.value.componentFakeRoom.step3PollsTitle
})

const signupCallToAction = computed(() => {
  return {
    action: 'Signup clicked',
    to: `https://${publicRuntimeConfig.appBaseUrl}/#/signup`,
    slot: data.value.componentGlobal.ctaGetStartedForFree,
  }
})

// Watchers
watch(
  () => hasInteractedWithColorPicker.value,
  (value) => {
    gsap.to(
      '.second-step.first-advice',
      {
        autoAlpha: value ? 0 : 1,
        duration: 20 / framesPerSecond.value,
        ease: 'expo.out',
      },
    )

    gsap.to(
      '.second-step.last-advice',
      {
        autoAlpha: value ? 1 : 0,
        duration: 20 / framesPerSecond.value,
        ease: 'expo.out',
      },
    )
  },
)

watch(
  () => hexColor.value,
  (value) => {
    $input.value.setValue(value)
  },
)

watch(
  () => hueColor.value,
  (value) => {
    colorsContext.value.fillStyle = value
    colorsContext.value.fillRect(0, 0, colorsContext.value.canvas.width, colorsContext.value.canvas.height)

    const whiteGradient = colorsContext.value.createLinearGradient(0, 0, colorsContext.value.canvas.width, 0)
    whiteGradient.addColorStop(0, 'rgba(255,255,255,1)')
    whiteGradient.addColorStop(1, 'rgba(255,255,255,0)')
    colorsContext.value.fillStyle = whiteGradient
    colorsContext.value.fillRect(0, 0, colorsContext.value.canvas.width, colorsContext.value.canvas.height)

    const blackGradient = colorsContext.value.createLinearGradient(0, 0, 0, colorsContext.value.canvas.height)
    blackGradient.addColorStop(0, 'rgba(0,0,0,0)')
    blackGradient.addColorStop(1, 'rgba(0,0,0,1)')
    colorsContext.value.fillStyle = blackGradient
    colorsContext.value.fillRect(0, 0, colorsContext.value.canvas.width, colorsContext.value.canvas.height)
  },
)

// Methods
function animateTooltips() {
  const tl = gsap.timeline({
    onComplete: () => {
      tl.pause()
      tl.seek(0)
      tl.play()
    },
  })

  tl.to(
    '.first-step.action .tooltip',
    {
      x: -6,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.in',
    },
    0,
  )

  tl.to(
    '.first-step.action .indicator',
    {
      duration: 4 / framesPerSecond.value,
      ease: 'expo.in',
      scale: 0.75,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.first-step.action .indicator',
    {
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
    },
    24 / framesPerSecond.value,
  )

  tl.to(
    '.first-step.action .indicator .circle',
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.first-step.action .tooltip',
    {
      x: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
    },
    24 / framesPerSecond.value,
  )

  tl.fromTo(
    '.first-step.action .indicator .circle',
    {
      scale: 0,
    },
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    50 / framesPerSecond.value,
  )

  tl.to(
    '.second-step.first-advice .tooltip',
    {
      y: 6,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.in',
    },
    0,
  )

  tl.to(
    '.second-step.first-advice .indicator',
    {
      duration: 4 / framesPerSecond.value,
      ease: 'expo.in',
      scale: 0.75,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.second-step.first-advice .indicator',
    {
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
    },
    24 / framesPerSecond.value,
  )

  tl.to(
    '.second-step.first-advice .indicator .circle',
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.second-step.first-advice .tooltip',
    {
      y: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
    },
    24 / framesPerSecond.value,
  )

  tl.fromTo(
    '.second-step.first-advice .indicator .circle',
    {
      scale: 0,
    },
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    50 / framesPerSecond.value,
  )

  tl.to(
    '.second-step.last-advice .tooltip',
    {
      x: -6,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.in',
    },
    0,
  )

  tl.to(
    '.second-step.last-advice .indicator',
    {
      duration: 4 / framesPerSecond.value,
      ease: 'expo.in',
      scale: 0.75,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.second-step.last-advice .indicator',
    {
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
    },
    24 / framesPerSecond.value,
  )

  tl.to(
    '.second-step.last-advice .indicator .circle',
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.second-step.last-advice .tooltip',
    {
      x: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
    },
    24 / framesPerSecond.value,
  )

  tl.fromTo(
    '.second-step.last-advice .indicator .circle',
    {
      scale: 0,
    },
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    50 / framesPerSecond.value,
  )

  tl.to(
    '.third-step.advice .tooltip',
    {
      x: 6,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.in',
    },
    0,
  )

  tl.to(
    '.third-step.advice .indicator',
    {
      duration: 4 / framesPerSecond.value,
      ease: 'expo.in',
      scale: 0.75,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.third-step.advice .indicator',
    {
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
    },
    24 / framesPerSecond.value,
  )

  tl.to(
    '.third-step.advice .indicator .circle',
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.third-step.advice .tooltip',
    {
      x: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
    },
    24 / framesPerSecond.value,
  )

  tl.fromTo(
    '.third-step.advice .indicator .circle',
    {
      scale: 0,
    },
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    50 / framesPerSecond.value,
  )

  tl.to(
    '.fourth-step.advice .tooltip',
    {
      y: 6,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.in',
    },
    0,
  )

  tl.to(
    '.fourth-step.advice .indicator',
    {
      duration: 4 / framesPerSecond.value,
      ease: 'expo.in',
      scale: 0.75,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.fourth-step.advice .indicator',
    {
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
    },
    24 / framesPerSecond.value,
  )

  tl.to(
    '.fourth-step.advice .indicator .circle',
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    20 / framesPerSecond.value,
  )

  tl.to(
    '.fourth-step.advice .tooltip',
    {
      y: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
    },
    24 / framesPerSecond.value,
  )

  tl.fromTo(
    '.fourth-step.advice .indicator .circle',
    {
      scale: 0,
    },
    {
      duration: 30 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
      stagger: 8 / framesPerSecond.value,
    },
    50 / framesPerSecond.value,
  )

  tl.play()
}

function createColorsCanvas() {
  const canvas = document.createElement('canvas')
  canvas.height = 238
  canvas.width = 238

  const context = canvas.getContext('2d', { willReadFrequently: true })

  document.querySelector('.colors').appendChild(canvas)

  return context
}

function createHueCanvas() {
  const canvas = document.createElement('canvas')
  canvas.height = 12
  canvas.width = 240

  const context = canvas.getContext('2d', { willReadFrequently: true })
  const gradient = context.createLinearGradient(0, 0, context.canvas.width, context.canvas.height)
  gradient.addColorStop(0, '#ff0000')
  gradient.addColorStop(1 / 6, '#ffff00')
  gradient.addColorStop(2 / 6, '#00ff00')
  gradient.addColorStop(3 / 6, '#00ffff')
  gradient.addColorStop(4 / 6, '#0000ff')
  gradient.addColorStop(5 / 6, '#ff00ff')
  gradient.addColorStop(6 / 6, '#ff0000')

  context.fillStyle = gradient
  context.fillRect(0, 0, context.canvas.width, context.canvas.height)

  document.querySelector('.hue').appendChild(canvas)

  return context
}

function createTimeline() {
  timeline = gsap.timeline()
  timeline.addLabel('step-1', 0)
  timeline.addLabel('step-2', 94 / framesPerSecond.value)
  timeline.addLabel('step-3', 240 / framesPerSecond.value)
  timeline.addLabel('step-4', 330 / framesPerSecond.value)
  timeline.addLabel('step-5', 435 / framesPerSecond.value)
  timeline.to(
    '.first-step.action',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',

    },
    `step-2-=${94 / framesPerSecond.value}`,
  )

  timeline.to(
    {},
    {
      duration: 15 / framesPerSecond.value,
      ease: 'expo.inOut',
      onUpdate: function () {
        const { length } = data.value.componentFakeRoom.step2EventName
        const end = Math.round((this.time() / (15 / framesPerSecond.value)) * length)
        eventName.value = data.value.componentFakeRoom.step2EventName.substring(0, end)
      },
    },
    `step-2-=${94 / framesPerSecond.value}`,
  )

  timeline.to(
    {},
    {
      duration: 6 / framesPerSecond.value,
      ease: 'expo.inOut',
      onUpdate: function () {
        const { length } = data.value.componentFakeRoom.step2EventName
        const end = Math.round((this.time() / (6 / framesPerSecond.value)) * length)
        eventSlug.value = $slugify(data.value.componentFakeRoom.step2EventName.substring(0, end))
      },
    },
    `step-2-=${79 / framesPerSecond.value}`,
  )

  timeline.to(
    '.first-step.input-group .radio.answer-1',
    {
      duration: 2 / framesPerSecond.value,
      ease: 'expo.in',
      scale: 0.95,
    },
    `step-2-=${69 / framesPerSecond.value}`,
  )

  timeline.to(
    '.first-step.input-group .radio.answer-1',
    {
      borderColor: '#0B54FE',
      duration: 10 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
    },
    `step-2-=${67 / framesPerSecond.value}`,
  )

  timeline.to(
    '.first-step.input-group .radio.answer-1 .state',
    {
      borderColor: '#0B54FE',
      borderWidth: 2,
      duration: 10 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
    },
    `step-2-=${67 / framesPerSecond.value}`,
  )

  timeline.to(
    '.first-step.input-group .radio.answer-1 .state .checked',
    {
      duration: 10 / framesPerSecond.value,
      ease: 'expo.out',
      scale: 1,
    },
    `step-2-=${67 / framesPerSecond.value}`,
  )

  timeline.to(
    '.first-step.input-group',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      scale: 0.8,
    },
    `step-2-=${27 / framesPerSecond.value}`,
  )

  timeline.from(
    '.second-step.registration-page',
    {
      x: 878,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      onStart: () => {
        currentStep.value = 1
      },
      onReverseComplete: () => {
        currentStep.value = 0
      },
    },
    `step-2-=${27 / framesPerSecond.value}`,
  )

  timeline.from(
    '.second-step.email',
    {
      y: -678,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-2-=${27 / framesPerSecond.value}`,
  )

  timeline.from(
    '.second-step.color-picker',
    {
      x: -632,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      onStart: () => {
        colorPickerHasBeenInitialized.value = true
      },
    },
    `step-2-=${27 / framesPerSecond.value}`,
  )

  timeline.from(
    '.second-step.action',
    {
      y: 168,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-2-=${27 / framesPerSecond.value}`,
  )

  timeline.from(
    '.second-step.first-advice',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-2-=${20 / framesPerSecond.value}`,
  )

  timeline.from(
    '.second-step.last-advice',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-2-=${20 / framesPerSecond.value}`,
  )

  timeline.to(
    '.second-step.last-advice',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      y: -230,
    },
    `step-3-=${140 / framesPerSecond.value}`,
  )

  timeline.to(
    '.second-step.email',
    {
      autoAlpha: 0,
      y: -622,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-3-=${140 / framesPerSecond.value}`,
  )

  timeline.to(
    '.second-step.registration-page',
    {
      autoAlpha: 0,
      y: -328,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-3-=${140 / framesPerSecond.value}`,
  )

  timeline.to(
    '.second-step.color-picker',
    {
      autoAlpha: 0,
      y: -380,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-3-=${140 / framesPerSecond.value}`,
  )

  timeline.to(
    '.second-step.action',
    {
      autoAlpha: 0,
      y: -230,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-3-=${140 / framesPerSecond.value}`,
  )

  timeline.from(
    '.third-step.background',
    {
      y: 986,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      onStart: () => {
        currentStep.value = 2
      },
      onReverseComplete: () => {
        currentStep.value = 1
      },
    },
    `step-3-=${140 / framesPerSecond.value}`,
  )

  timeline.from(
    [
      '.third-step.metadata',
      '.third-step.streams',
    ],
    {
      y: 986,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-3-=${135 / framesPerSecond.value}`,
  )

  timeline.from(
    [
      '.third-step.nav',
      '.third-step.sidebar',
    ],
    {
      x: 476,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-3-=${130 / framesPerSecond.value}`,
  )

  timeline.from(
    '.third-step.sidebar .body .messages .message',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      stagger: (20 / framesPerSecond.value) / 7,
      y: 10,
    },
    `step-3-=${110 / framesPerSecond.value}`,
  )

  timeline.to(
    '.third-step.sidebar .slider',
    {
      xPercent: -50,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      onStart: () => {
        chatTabIsActive.value = false
        pollsTabIsActive.value = true
      },
    },
    `step-3-=${60 / framesPerSecond.value}`,
  )

  timeline.from(
    '.third-step.advice',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-3-=${20 / framesPerSecond.value}`,
  )

  timeline.to(
    '.third-step.advice',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-4-=${90 / framesPerSecond.value}`,
  )

  timeline.to(
    [
      '.third-step.nav',
      '.third-step.sidebar',
    ],
    {
      x: 452,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-4-=${90 / framesPerSecond.value}`,
  )

  timeline.to(
    [
      '.third-step.metadata',
      '.third-step.streams',
    ],
    {
      y: 958,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-4-=${90 / framesPerSecond.value}`,
  )

  timeline.to(
    '.third-step.background',
    {
      y: 988,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-4-=${88 / framesPerSecond.value}`,
  )

  timeline.from(
    [
      '.fourth-step.shadow',
      '.fourth-step.profile',
    ],
    {
      y: 376,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      onStart: () => {
        currentStep.value = 3
      },
      onReverseComplete: () => {
        currentStep.value = 2
      },
    },
    `step-4-=${88 / framesPerSecond.value}`,
  )

  timeline.from(
    [
      '.fourth-step.stats',
      '.fourth-step.attendees',
    ],
    {
      autoAlpha: 0,
      y: 100,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-4-=${88 / framesPerSecond.value}`,
  )

  timeline.from(
    [
      '.fourth-step.stats .graph .registered',
      '.fourth-step.stats .graph .attended',
    ],
    {
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      height: 0,
    },
    `step-4-=${78 / framesPerSecond.value}`,
  )

  timeline.from(
    [
      '.fourth-step.stats .graph .registered p',
      '.fourth-step.stats .graph .attended p',
    ],
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-4-=${78 / framesPerSecond.value}`,
  )

  timeline.from(
    graphValues.value,
    {
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      value: 0,
      stagger: (20 / framesPerSecond.value) / (graphValues.value.length - 1),
    },
    `step-4-=${78 / framesPerSecond.value}`,
  )

  timeline.from(
    '.fourth-step.action',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-4-=${40 / framesPerSecond.value}`,
  )

  timeline.from(
    '.fourth-step.advice',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.out',
    },
    `step-4-=${20 / framesPerSecond.value}`,
  )

  timeline.to(
    [
      '.fourth-step.stats',
      '.fourth-step.attendees',
      '.fourth-step.shadow',
      '.fourth-step.profile',
      '.fourth-step.action',
      '.fourth-step.advice',
    ],
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
    },
    `step-5-=${105 / framesPerSecond.value}`,
  )

  timeline.from(
    '.fifth-step.apps',
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      onStart: () => {
        currentStep.value = 4
      },
      onReverseComplete: () => {
        currentStep.value = 3
      },
      scale: 0.9,
    },
    `step-5-=${105 / framesPerSecond.value}`,
  )

  timeline.to(
    '.fifth-step.apps .table .body .enabled.active',
    {
      backgroundColor: '#2EB89A',
      borderColor: '#2EB89A',
      duration: 25 / framesPerSecond.value,
      ease: 'expo.inOut',
      stagger: 0.1,
    },
    `step-5-=${85 / framesPerSecond.value}`,
  )

  timeline.to(
    '.fifth-step.apps .table .body .enabled.active .icon',
    {
      color: '#FFFFFF',
      duration: 25 / framesPerSecond.value,
      ease: 'expo.inOut',
      stagger: 0.1,
    },
    `step-5-=${85 / framesPerSecond.value}`,
  )

  timeline.from(
    [
      '.fifth-step.filter',
      '.fifth-step.call-to-action',
    ],
    {
      autoAlpha: 0,
      duration: 20 / framesPerSecond.value,
      ease: 'expo.inOut',
      scale: 0.9,
    },
    `step-5-=${20 / framesPerSecond.value}`,
  )

  timeline.pause()
}

function findClosestColor(targetColor, colors) {
  let closestDistance = Infinity
  let closestColor = null

  const { red: r1, green: g1, blue: b1 } = targetColor

  // Loop through the array of colors
  colors.forEach((color) => {
    const { red: r2, green: g2, blue: b2 } = color

    // Calculate the Euclidean distance between the target color and current color
    const distance = Math.sqrt(
      (r1 - r2) ** 2
      + (g1 - g2) ** 2
      + (b1 - b2) ** 2,
    )

    // Update closest color and distance if the current distance is smaller than the closest distance
    if (distance < closestDistance) {
      closestDistance = distance
      closestColor = color
    }
  })

  return closestColor
}

function getContrastDiff({ red, green, blue }) {
  const yiq = (red * 299 + green * 587 + blue * 114) / 1000
  return yiq
}

function goTo(to) {
  const from = `step-${currentStep.value + 1}`
  const targetStep = Number(to.replace('step-', '')) - 1

  timeline.timeScale(targetStep > currentStep.value ? 1 : 1.5)
  timeline.tweenFromTo(
    from,
    to,
  )
}

function hexToRgb(hex) {
  const [_, red, green, blue] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return {
    red: Number.parseInt(red, 16),
    green: Number.parseInt(green, 16),
    blue: Number.parseInt(blue, 16),
  }
}

function onColorsClick({ layerX, layerY }) {
  colorsCursorX.value = layerX
  colorsCursorY.value = layerY
  updateHexColor()
}

function onColorsCursorDragStart() {
  colorsCursorIsDraggable.value = true
  const dragEnd = 'ontouchend' in document ? 'touchend' : 'mouseup'
  document.addEventListener(dragEnd, onColorsCursorDragEnd)
}

function onColorsCursorDrag(event) {
  if (!colorsCursorIsDraggable.value) {
    return
  }

  const { clientX, clientY } = event.type === 'touchmove' ? event.touches[0] : event
  const { height, width, x, y } = $colorsCursor.value.$el.parentNode.getBoundingClientRect()

  colorsCursorX.value = Math.min(Math.max(clientX - x, 6), width - 8)
  colorsCursorY.value = Math.min(Math.max(clientY - y, 6), height - 8)
}

function onColorsCursorDragEnd() {
  const dragEnd = 'ontouchend' in document ? 'touchend' : 'mouseup'
  document.removeEventListener(dragEnd, onColorsCursorDragEnd)
  colorsCursorIsDraggable.value = false
  updateHexColor()
}

function onHueClick({ layerX }) {
  hueCursorX.value = layerX
  nextTick(() => {
    updateHexColor()
  })
}

function onHueCursorDragStart() {
  hueCursorIsDraggable.value = true
  const dragEnd = 'ontouchend' in document ? 'touchend' : 'mouseup'
  document.addEventListener(dragEnd, onHueCursorDragEnd)
}

function onHueCursorDrag(event) {
  if (!hueCursorIsDraggable.value) {
    return
  }

  const { clientX } = event.type === 'touchmove' ? event.touches[0] : event
  const { width, x } = $hueCursor.value.$el.parentNode.getBoundingClientRect()

  hueCursorX.value = Math.min(Math.max(clientX - x, 0), width)
}

function onHueCursorDragEnd() {
  const dragEnd = 'ontouchend' in document ? 'touchend' : 'mouseup'
  document.removeEventListener(dragEnd, onHueCursorDragEnd)
  hueCursorIsDraggable.value = false
  nextTick(() => {
    updateHexColor()
  })
}

function onInputBlur(value) {
  if (!(value.startsWith('#') && value.length === 7)) {
    updateHexColor()
  }
}

function onInputChange(value) {
  if (/^#[0-9A-F]{6}$/i.test(value.toUpperCase())) {
    const targetColor = hexToRgb(value)
    const hsl = rgbToHsl(targetColor)

    hasInteractedWithColorPicker.value = true
    hexColor.value = value
    hueCursorX.value = hsl.h / 360 * 238

    const relatedColors = []

    nextTick(() => {
      for (let x = 0; x <= 238; x++) {
        for (let y = 0; y <= 238; y++) {
          const { data } = colorsContext.value.getImageData(x, y, 1, 1)
          const [red, green, blue] = data

          relatedColors.push({ red, green, blue, x, y })
        }
      }

      const closestColor = findClosestColor(targetColor, relatedColors)

      colorsCursorX.value = closestColor.x
      colorsCursorY.value = closestColor.y
    })
  }
}

function rgbToHex({ red, green, blue }) {
  const hex = (1 << 24 | red << 16 | green << 8 | blue).toString(16).slice(1)
  return `#${hex}`
}

function rgbToHsl({ red, green, blue }) {
  const r = red / 255
  const g = green / 255
  const b = blue / 255
  const l = Math.max(r, g, b)
  const s = l - Math.min(r, g, b)
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
        ? 2 + (b - r) / s
        : 4 + (r - g) / s
    : 0
  return {
    h: 60 * h < 0 ? 60 * h + 360 : 60 * h,
    s: 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    l: (100 * (2 * l - s)) / 2,
  }
}

function updateHexColor() {
  const { data } = colorsContext.value.getImageData(colorsCursorX.value, colorsCursorY.value, 1, 1)
  const [red, green, blue] = data
  hasInteractedWithColorPicker.value = true
  hexColor.value = rgbToHex({ red, green, blue })
}

// Lifecycle Hooks
onBeforeUnmount(() => {
  const dragStart = 'ontouchstart' in document ? 'touchstart' : 'mousedown'
  const drag = 'ontouchmove' in document ? 'touchmove' : 'mousemove'

  $colorsCursor.value.$el.removeEventListener(dragStart, onColorsCursorDragStart)
  $hueCursor.value.$el.removeEventListener(dragStart, onHueCursorDragStart)
  colorsContext.value.canvas.removeEventListener('click', onColorsClick)
  hueContext.value.canvas.removeEventListener('click', onHueClick)
  document.removeEventListener(drag, onColorsCursorDrag)
  document.removeEventListener(drag, onHueCursorDrag)

  gsap.killTweensOf('.first-step.action .indicator')
  gsap.killTweensOf('.first-step.action .tooltip')

  timeline?.kill()
  timeline = null
})

onMounted(() => {
  const dragStart = 'ontouchstart' in document ? 'touchstart' : 'mousedown'
  const drag = 'ontouchmove' in document ? 'touchmove' : 'mousemove'

  $colorsCursor.value.$el.addEventListener(dragStart, onColorsCursorDragStart)
  $hueCursor.value.$el.addEventListener(dragStart, onHueCursorDragStart)
  document.addEventListener(drag, onColorsCursorDrag)
  document.addEventListener(drag, onHueCursorDrag)

  colorsContext.value = createColorsCanvas()
  colorsContext.value.canvas.addEventListener('click', onColorsClick)
  hueContext.value = createHueCanvas()
  hueContext.value.canvas.addEventListener('click', onHueClick)

  animateTooltips()
  createTimeline()
})
</script>

<style lang="postcss" scoped>
.product-tour {
  @apply hidden;

  @screen xl {
    @apply flex justify-center h-[900px] bg-white;
  }

  .container {
    @apply relative flex-col items-center max-w-[1280px] mb-20 mt-auto py-20 bg-winter-green-100 rounded-[48px];

    .headline {
      @apply overflow-hidden;

      @screen mdminus {
        @apply h-10;
      }

      @screen mdplus {
        @apply h-16;
      }

      .slider {
        @apply flex-col transition-transform duration-600 ease-in-out;

        .h2 {
          @apply text-center z-10;
        }
      }
    }

    .progress {
      @apply justify-center w-auto mt-2;

      .step {
        @apply h-[6px] w-6 mx-1 bg-winter-green-200 rounded-xs transition-all duration-600 ease-in-out;

        &.active {
          @apply w-12 bg-winter-green-500;
        }

        &.back {
          @apply bg-winter-green-400 cursor-pointer;

          &:hover {
            @apply w-8;
          }
        }
      }
    }

    .animation {
      @apply relative h-[493px] max-w-840 mt-8 bg-white rounded-3xl shadow-light2 overflow-hidden z-10;

      .first-step {
        &.action {
          @apply absolute right-[176px] top-[73px] items-center w-auto cursor-pointer;

          .indicator {
            @apply relative flex-shrink-0 h-8 w-8 bg-winter-green-600 rounded-full;

            .circle {
              @apply absolute top-1/2 left-1/2 h-8 w-8 bg-white/30 rounded-full transform -translate-x-1/2 -translate-y-1/2 scale-0;
            }

            .circle + .circle {
              @apply bg-winter-green-600;
            }
          }

          .tooltip {
            @apply relative items-center w-auto ml-[9px] px-4 py-3 bg-winter-green-600 text-white text-sm leading-normal rounded-xs;

            svg {
              @apply absolute -left-[7px];
            }
          }
        }

        &.input-group {
          @apply absolute left-16 flex-col max-w-[712px];

          &.name {
            @apply top-16;
          }

          &.permissions {
            @apply top-[209px];
          }

          label {
            @apply font-inter font-medium text-sm text-[#333335] leading-6 normal-case;
          }

          input {
            @apply mt-2 px-3 py-2 bg-white border-[#C8D3DA] font-inter font-normal text-sm text-[#232B2F] leading-6 rounded-xs cursor-pointer;

            &::placeholder {
              @apply text-sm text-[#5D6D79];
            }
          }

          .url {
            @apply self-start w-auto mt-2 px-3 py-2 bg-grey-blue-100 font-inter text-[13px] text-[#5D6D79] leading-4 rounded-xs cursor-default;

            span {
              @apply ml-2 font-medium text-livestorm-blue-600;
            }
          }

          .radio {
            @apply mt-2 px-3 py-2 border border-[#C8D3DA] rounded-xs cursor-default;

            .state {
              @apply self-center items-center justify-center flex-shrink-0 h-4 w-4 mr-3 border border-grey-blue-300 rounded-full;

              .checked {
                @apply h-[6px] w-[6px] bg-livestorm-blue-600 transform scale-0 rounded-full;
              }
            }

            .value {
              @apply flex-col;

              p {
                @apply font-inter font-medium text-sm text-[#232B2F] leading-6;
              }

              p + p {
                @apply font-normal text-[#5D6D79];
              }
            }
          }
        }
      }

      .second-step {
        &.action {
          @apply absolute left-16 top-[423px];

          .button {
            @apply px-4;

            .icon {
              @apply text-base text-white leading-none;
            }
          }
        }

        &.first-advice {
          @apply absolute left-[235px] top-2 flex-col items-center w-auto cursor-pointer;

          .tooltip {
            @apply relative justify-center w-auto px-4 py-3 bg-winter-green-600 text-white text-sm leading-normal rounded-xs;

            svg {
              @apply absolute -bottom-[7px];
            }
          }

          .indicator {
            @apply relative flex-shrink-0 h-8 w-8 mt-[9px] bg-winter-green-600 rounded-full;

            .circle {
              @apply absolute top-1/2 left-1/2 h-8 w-8 bg-white/30 rounded-full transform -translate-x-1/2 -translate-y-1/2 scale-0;
            }

            .circle + .circle {
              @apply bg-winter-green-600;
            }
          }
        }

        &.last-advice {
          @apply absolute bottom-[47px] left-[193px] items-center w-auto cursor-pointer opacity-0 invisible;

          .indicator {
            @apply relative flex-shrink-0 h-8 w-8 bg-winter-green-600 rounded-full;

            .circle {
              @apply absolute top-1/2 left-1/2 h-8 w-8 bg-white/30 rounded-full transform -translate-x-1/2 -translate-y-1/2 scale-0;
            }

            .circle + .circle {
              @apply bg-winter-green-600;
            }
          }

          .tooltip {
            @apply relative items-center w-auto ml-[9px] px-4 py-3 bg-winter-green-600 text-white text-sm leading-normal rounded-xs;

            svg {
              @apply absolute -left-[7px];
            }
          }
        }

        &.color-picker {
          @apply absolute left-16 top-16 flex-col w-60;

          .colors {
            @apply relative h-60 w-full border border-grey-blue-300 rounded-xs overflow-hidden;

            .cursor {
              @apply absolute top-[6px] left-[6px] h-3 w-3 border-2 border-white rounded-full z-10 transform -translate-x-1/2 -translate-y-1/2;
            }
          }

          .hue {
            @apply relative h-3 w-full mt-2 rounded-xs overflow-hidden;

            .cursor {
              @apply absolute top-[6px] left-[6px] h-3 w-3 border-2 border-white rounded-full z-10 transform -translate-x-1/2 -translate-y-1/2;
            }
          }

          .input-group {
            @apply relative flex-col mt-4;

            .color {
              @apply absolute left-3 top-[38px] h-5 w-5 content-[''] rounded-[3px] z-10;
            }

            label {
              @apply font-inter font-medium text-sm text-[#333335] leading-6 normal-case;
            }

            input {
              @apply mt-1 pl-10 pr-3 py-2 bg-white border-[#C8D3DA] font-inter font-normal text-sm text-[#232B2F] leading-6 rounded-xs;
            }
          }
        }

        &.email {
          @apply absolute left-[385px] top-[174px] flex-col h-[436px] w-[315px] bg-white border-[0.524px] border-grey-blue-100 rounded-2xl overflow-hidden z-10;
          box-shadow: 0px 0.9674733877182007px 1.9349467754364014px 0px rgba(13, 22, 38, 0.07), 0px 1.9349467754364014px 3.8698935508728027px 0px rgba(13, 22, 38, 0.07);

          &.dark {
            .main {
              .button {
                @apply text-grey-blue-900 !important;
              }
            }
          }

          .hero {
            @apply justify-center py-[19.2px] pb-[20.1px];

            .organisation {
              @apply items-center justify-center h-8 w-8 bg-white rounded-[8.22px];

              picture {
                @apply rounded-none;
                filter: brightness(0) saturate(100%) invert(8%) sepia(16%) saturate(2002%) hue-rotate(145deg) brightness(102%) contrast(89%);
              }
            }
          }

          .main {
            @apply flex-col px-[12.6px];

            .for {
              @apply mt-[16.8px] font-inter text-center text-[8.385px] text-grey-blue-600 leading-normal;
            }

            .title {
              @apply mt-[4.19px] font-inter font-bold text-center text-[9.433px] text-winter-green-900 leading-[12.577px];
            }

            .date {
              @apply mt-[4.19px] font-inter text-[6.289px] text-center text-grey-blue-600 leading-[10.481px] uppercase;
            }

            .message {
              @apply mt-4 p-[12.6px] bg-grey-blue-100 font-inter text-[8.385px] text-grey-blue-600 leading-normal rounded-xs;
            }

            .shape {
              @apply ml-[14.15px];
            }

            .author {
              @apply items-center mt-2;

              .avatar {
                @apply flex-shrink-0 mx-[8.38px] rounded-full;
              }

              p {
                @apply font-inter font-semibold text-winter-green-900 text-[8.38px] leading-none;
              }
            }

            .button {
              @apply h-auto w-auto mx-auto mt-[16.8px] px-[12.6px] py-0 font-["Arial"] font-bold text-white text-[9.433px] leading-[26px] rounded-md cursor-default transition-none;
            }
          }
        }

        &.registration-page {
          @apply absolute left-[428px] top-16 flex-col h-[431px] max-w-[634px] rounded-t-2xl cursor-default shadow-light1 overflow-hidden z-0;

          &.dark {
            .hero {
              .content {
                .invited-by,
                .title,
                .date p,
                .countdown .item .number,
                .login {
                  @apply text-grey-blue-900 !important;
                }

                .countdown .item .unit {
                  @apply text-grey-blue-600 !important;
                }
              }
            }
          }

          .hero {
            @apply relative justify-center h-[215px] w-full;

            .cover {
              @apply absolute inset-0 z-0;

              picture {
                @apply rounded-none;
              }
            }

            .layer {
              @apply absolute inset-0 opacity-50 z-10;
            }

            .content {
              @apply flex-col items-center max-w-[486px] mt-[14.8px] z-20;

              .invited-by {
                @apply font-inter font-semibold text-[7.049px] text-[#EAEEF1] text-center leading-normal;
              }

              .organisation {
                @apply items-center justify-center h-[43.177px] w-[43.177px] mt-[7.4px] bg-white rounded-[11.092px];

                picture {
                  @apply rounded-none;
                  filter: brightness(0) saturate(100%) invert(8%) sepia(16%) saturate(2002%) hue-rotate(145deg) brightness(102%) contrast(89%);
                }
              }

              .title {
                @apply mt-[7.4px] font-inter font-semibold text-[14.099px] text-white leading-tight;
              }

              .date {
                @apply items-center h-[17.6px] w-auto mt-[7.4px] px-[5.29px] bg-white/10 rounded-[5.287px];

                p {
                  @apply font-inter text-[6.168px] text-[#E5E5EA] leading-[17.623px];
                }

                i {
                  @apply ml-[6.23px] text-white text-[7.049px] leading-none;
                }
              }

              .countdown {
                @apply items-center justify-between h-[26.4px] w-[159px] mt-[3.7px];

                .item {
                  @apply flex-col items-center;

                  .number {
                    @apply font-inter font-semibold text-white text-[5.287px] leading-normal;
                  }

                  .unit {
                    @apply font-inter font-semibold text-[5.287px] text-[#C8D3DA] leading-[7.049px];
                  }
                }
              }

              .register {
                @apply w-auto mt-[3.7px];

                input {
                  @apply h-auto w-auto px-[7.05px] py-0 bg-white border-[0.441px] border-[#C8D3DA] font-inter text-[7.049px] text-[#5D6D79] leading-[21.148px] rounded-[3.697px] cursor-default;
                }

                .button {
                  @apply h-auto w-auto ml-[3.53px] px-[7.05px] py-0 bg-[#EAEEF1] font-inter font-semibold text-[7.049px] text-[#333335] leading-normal rounded-[3.697px] cursor-default;

                  &:hover {
                    @apply bg-[#EAEEF1];
                  }
                }
              }

              .login {
                @apply mt-[7.4px] font-inter font-semibold text-white text-[7.049px] leading-[10.574px] underline;
              }
            }
          }

          .main {
            @apply flex-col items-center max-w-[486px] mt-[14.1px] z-20;

            .info {
              @apply max-w-[317px] px-[10.6px] py-[7px] bg-white border-[0.5px] border-grey-blue-200 rounded-[3.7px];

              .item {
                @apply items-center;

                &:last-child {
                  @apply ml-[34.4px];
                }

                .icon {
                  @apply text-[8.8px] text-grey-blue-600 leading-none;
                }

                p {
                  @apply font-inter ml-[3.5px] text-[#5D6D79] text-[7px] leading-normal;
                }
              }
            }

            .about {
              @apply flex-col max-w-[317px] mt-[10.6px] p-[10.6px] bg-white border-[0.5px] border-grey-blue-200 rounded-[3.7px];

              p {
                @apply font-inter font-semibold text-grey-blue-600 text-[5.3px] leading-[7px] uppercase;
              }

              .placeholder {
                @apply flex-col mt-[7.9px];

                .line {
                  @apply h-[6.2px] w-full bg-grey-blue-200 rounded-[2.6px];

                  &:last-child {
                    @apply w-3/4;
                  }
                }

                .line + .line {
                  @apply mt-[4.4px];
                }
              }

              .placeholder + .placeholder {
                @apply mt-[15.4px];

                .line {
                  &:last-child {
                    @apply w-1/4;
                  }
                }
              }
            }
          }
        }
      }

      .third-step {
        &.advice {
          @apply absolute top-[15px] right-[105.5px] items-start w-auto cursor-pointer;

          .tooltip {
            @apply relative max-w-[148px] w-auto mr-[9px] px-4 py-3 bg-winter-green-600 text-white text-sm leading-normal rounded-xs;

            svg {
              @apply absolute -right-[7px] top-[16px];
            }
          }

          .indicator {
            @apply relative flex-shrink-0 h-8 w-8 mt-[5px] bg-winter-green-600 rounded-full;

            .circle {
              @apply absolute top-1/2 left-1/2 h-8 w-8 bg-white/30 rounded-full transform -translate-x-1/2 -translate-y-1/2 scale-0;
            }

            .circle + .circle {
              @apply bg-winter-green-600;
            }
          }
        }

        &.background {
          @apply absolute inset-0 h-full w-full;
        }

        &.metadata {
          @apply absolute left-6 top-6 h-[28px] w-[792px];

          &.dark {
            .organisation {
              picture {
                filter: brightness(0) saturate(100%) invert(8%) sepia(16%) saturate(2002%) hue-rotate(145deg) brightness(102%) contrast(89%) !important;
              }
            }

            .event {
              p {
                @apply text-grey-blue-900 !important;
              }
            }

            .started-at {
              p {
                @apply text-grey-blue-900 !important;
              }
            }
          }

          .organisation {
            @apply flex-shrink-0 items-center justify-center h-[27.5px] w-[27.5px] bg-white rounded-[7.064px];

            picture {
              @apply rounded-none;
              filter: var(--filter);
            }
          }

          .event {
            @apply flex-shrink-0 flex-col w-auto ml-[5.5px];

            p {
              @apply font-inter font-semibold text-white text-[10px] leading-[13.5px];
            }

            p + p {
              @apply font-normal text-[9.3px];
            }

            .live {
              @apply inline-block px-[4.1px] bg-live-red-500 text-white text-[7.6px] leading-[11px] rounded-[62.7px];
            }
          }

          .started-at {
            @apply flex-shrink-0 w-auto ml-auto;

            p {
              @apply font-inter font-semibold text-[9.5px] text-white leading-[27.5px];
            }
          }

          .action {
            @apply flex-shrink-0 w-auto ml-[11px];

            .button {
              @apply h-auto px-[8.2px] py-[5.5px] bg-[#E5453880] border-[0.7px] border-black/10 font-inter font-semibold text-[9.3px] leading-[16.5px] rounded-[4px];
            }
          }
        }

        &.nav {
          @apply absolute bottom-6 right-6 w-auto;

          .item {
            @apply flex-col items-center justify-center h-[31px] w-[46.5px] rounded-md;

            &.active {
              @apply bg-white;

              picture {
                filter: var(--filter);
              }

              p {
                @apply text-[var(--color)];
              }
            }

            picture {
              @apply rounded-none;
            }

            p {
              @apply mt-[2px] font-inter font-semibold text-white text-[7px] leading-[10px];
            }
          }
        }

        &.sidebar {
          @apply absolute right-6 top-16 flex-col h-[365px] w-[198px] bg-white border-[0.6px] border-black/10 rounded-xs;

          &.chat {
            .footer {
              @apply flex;
            }
          }

          .head {
            @apply items-center h-[35.9px] pl-[9.4px] pr-[14.1px];

            picture {
              @apply rounded-none;
              filter: brightness(0) saturate(100%) invert(11%) sepia(10%) saturate(1079%) hue-rotate(156deg) brightness(102%) contrast(88%);
            }

            p {
              @apply ml-[4.5px] font-inter font-semibold text-grey-blue-900 text-[11.2px] leading-[17.9px];
            }

            .icon {
              @apply ml-auto text-grey-blue-600 text-[8.9px] leading-none;
            }
          }

          .body {
            @apply relative overflow-hidden;

            .slider {
              @apply flex-row flex-wrap min-w-[396px];

              .view {
                @apply flex-col h-full w-[198px] px-[9.4px];

                &.messages {
                  .message {
                    @apply mt-[6.7px];

                    .avatar {
                      @apply flex-shrink-0 mr-[4.5px];
                    }

                    .content {
                      @apply flex-col;

                      p {
                        @apply font-inter font-semibold text-grey-blue-900 text-[8px] leading-[11px];
                      }

                      p + p {
                        @apply font-normal text-[8px] text-grey-blue-700 leading-[11px];
                      }
                    }
                  }
                }

                &.poll {
                  @apply mt-2;

                  .question {
                    @apply font-inter font-medium text-grey-blue-800 text-[9.4px] leading-normal;
                  }

                  .answers {
                    @apply flex-col mt-[9.4px];

                    .answer {
                      @apply justify-between mt-[4.7px] p-[3.8px] pt-[4.7px] border-[0.6px] border-grey-blue-300 rounded-[4.7px];

                      &:first-child {
                        @apply mt-0;
                      }

                      p {
                        @apply font-inter font-medium text-grey-blue-900 text-[8.2px] leading-[14.1px];
                      }
                    }
                  }

                  .results {
                    @apply items-center mt-[9.4px];

                    .button {
                      @apply h-auto px-[7px] py-0 bg-grey-blue-200 font-inter text-[8.2px] text-grey-blue-800 leading-[18.8px] rounded-[2.4px] cursor-default;

                      &:hover {
                        @apply bg-grey-blue-200;
                      }
                    }

                    p {
                      @apply ml-[9.4px] font-inter text-[8.5px] text-grey-blue-600 leading-normal;
                    }
                  }
                }
              }
            }
          }

          .footer {
            @apply hidden items-center h-[29px] mt-auto px-[9.4px] border-t-[0.6px] border-black/10;

            p {
              @apply ml-[6.7px] mr-auto font-inter text-grey-blue-600 text-[7.8px] leading-[11.2px];
            }

            picture {
              @apply rounded-none;
              filter: brightness(0) saturate(100%) invert(43%) sepia(6%) saturate(1157%) hue-rotate(163deg) brightness(93%) contrast(86%);
            }
          }
        }

        &.streams {
          @apply absolute left-6 top-16 flex-col h-auto w-[582px];

          .primary {
            @apply relative h-[277px] w-full bg-white/10 rounded-xs overflow-hidden;

            &::after {
              @apply absolute bottom-0 left-0 right-0 h-[39.4px] bg-gradient-to-b from-transparent to-black/10 w-full z-0 content-[''];
            }

            video {
              @apply rounded-none;
            }

            .user {
              @apply absolute bottom-[5px] left-[5px] items-center z-10;

              picture {
                @apply rounded-full;
              }

              p {
                @apply ml-[3.1px] font-inter font-semibold text-[8px] text-white leading-none;
              }
            }
          }

          .secondaries {
            @apply justify-center h-[80.8px] w-full mt-1;

            .stream {
              @apply relative h-full w-[127.6px] mx-[2px] bg-white/10 rounded-xs overflow-hidden;

              &::after {
                @apply absolute bottom-0 left-0 right-0 h-[39.4px] bg-gradient-to-b from-transparent to-black/10 w-full z-0 content-[''];
              }

              video {
                @apply rounded-none;
              }

              .user {
                @apply absolute bottom-[5px] left-[5px] items-center z-10;

                picture {
                  @apply rounded-full;
                }

                p {
                  @apply ml-[3.1px] font-inter font-semibold text-[8px] text-white leading-none;
                }
              }
            }
          }

          .actions {
            @apply justify-center h-[31px] w-auto mt-3;

            .item {
              @apply flex-col items-center justify-center h-full w-[46.5px] bg-blizzard-100 border-l-[0.325px] border-r-[0.325px] border-blizzard-300;

              &:first-child {
                @apply border-l-0 rounded-l-md;
              }

              &:last-child {
                @apply border-r-0 rounded-r-md;
              }

              picture {
                @apply rounded-none;
                filter: brightness(0) saturate(100%) invert(25%) sepia(18%) saturate(1117%) hue-rotate(163deg) brightness(98%) contrast(100%);
              }

              p {
                @apply mt-[2px] font-inter font-semibold text-blizzard-800 text-[7px] leading-[10px];
              }
            }
          }
        }
      }

      .fourth-step {
        &.action {
          @apply absolute bottom-10 right-[59.3px] w-auto z-20;

          .button {
            @apply px-4;

            .icon {
              @apply text-base text-white leading-none;
            }
          }
        }

        &.advice {
          @apply absolute bottom-[70px] right-[28px] flex-col items-center w-auto cursor-pointer z-20;

          .tooltip {
            @apply relative justify-center w-auto px-4 py-3 bg-winter-green-600 text-white text-sm leading-normal rounded-xs;

            svg {
              @apply absolute -bottom-[7px];
            }
          }

          .indicator {
            @apply relative flex-shrink-0 h-8 w-8 mt-[9px] bg-winter-green-600 rounded-full;

            .circle {
              @apply absolute top-1/2 left-1/2 h-8 w-8 bg-white/30 rounded-full transform -translate-x-1/2 -translate-y-1/2 scale-0;
            }

            .circle + .circle {
              @apply bg-winter-green-600;
            }
          }
        }

        &.attendees {
          @apply absolute left-16 right-16 top-[238.2px] flex-col h-[233px] max-w-[712px];

          .head {
            @apply h-[21.7px] px-[14.8px];

            p {
              @apply px-[6.35px] font-inter font-semibold text-grey-blue-700 text-[6.3px] leading-[21.7px] uppercase;

              &:nth-child(1) {
                @apply w-[62.5px];
              }

              &:nth-child(2) {
                @apply w-[88.4px];
              }

              &:nth-child(3) {
                @apply w-[127.6px];
              }

              &:nth-child(4) {
                @apply w-[190px];
              }

              &:nth-child(5) {
                @apply w-[45px];
              }

              &:nth-child(6) {
                @apply w-[77.3px];
              }

              &:nth-child(7) {
                @apply w-[91.6px];
              }
            }
          }

          .body {
            @apply flex-col mt-[1.9px];

            .row {
              @apply items-center h-[33.9px] mt-[8.4px] px-[14.8px];

              .avatar {
                @apply w-[62.5px];

                picture {
                  @apply ml-[11.9px] rounded-full;
                }
              }

              .attended {
                @apply w-[45px] px-[6.35px];

                .icon {
                  @apply relative text-[8px] leading-none;

                  &.icon-check {
                    @apply text-winter-green-500;

                    &::after {
                      @apply border-winter-green-500;
                    }
                  }

                  &.icon-x {
                    @apply text-live-red-600;

                    &::after {
                      @apply border-live-red-600;
                    }
                  }

                  &::after {
                    @apply absolute left-1/2 top-1/2 h-[10.6px] w-[10.6px] bg-transparent border-[0.6px] rounded-full transform -translate-x-1/2 -translate-y-1/2 content-[''];
                  }
                }
              }

              p {
                @apply font-inter text-grey-blue-800 text-[7.4px] leading-[12.7px];

                &.name {
                  @apply w-[88.4px];
                }

                &.email {
                  @apply w-[127.6px];
                }

                &.registration-date {
                  @apply w-[190px] px-[6.35px];
                }

                &.attendance-rate {
                  @apply w-[77.3px] px-[6.35px];
                }

                &.attendance-duration {
                  @apply w-[91.6px] px-[6.35px];
                }
              }
            }
          }
        }

        &.profile {
          @apply absolute -bottom-[26px] right-[85px] h-[296px] w-[313px] bg-white rounded-[13.2px] z-10;
          box-shadow: 0px 0.5493044257164001px 1.0986088514328003px 0px rgba(13, 22, 38, 0.04), 0px 1.0986088514328003px 2.1972177028656006px 0px rgba(13, 22, 38, 0.04), 0px 2.1972177028656006px 4.394435405731201px 0px rgba(13, 22, 38, 0.04);

          .inner {
            @apply relative flex-col items-start h-full w-full mt-0 px-[17.6px] pt-[26.4px] pb-[22px];

            .avatar {
              @apply absolute left-[13.7px] -top-[14px];

              picture {
                @apply border-[1.6px] border-livestorm-blue-400 rounded-full;
              }
            }

            .title {
              @apply font-inter font-medium text-grey-blue-700 text-[8.7px] leading-[13px] uppercase;
            }

            .label {
              @apply mt-[8.8px] font-inter font-medium text-grey-blue-900 text-[8.7px] leading-[13px];
            }

            .value {
              @apply mt-[2.2px] font-inter font-medium text-grey-blue-600 text-[8.7px] leading-[13px];

              &.email {
                @apply text-livestorm-blue-600;
              }
            }

            .value + .title {
              @apply mt-[17.6px];
            }
          }
        }

        &.shadow {
          @apply absolute -bottom-[21px] right-[85px] h-[296px] w-[313px] px-[17.6px] pt-[26.4px] pb-[22px] bg-livestorm-blue-400 rounded-[13.2px] z-0;
        }

        &.stats {
          @apply absolute left-[75px] right-[75px] top-[50px] justify-between h-[148.7px] max-w-[690px] pt-[16.6px] pr-[21.1px] pb-[4.5px] pl-[12.1px];

          .funnel {
            @apply flex-col w-[112px];

            .title {
              @apply font-inter font-medium text-grey-blue-900 text-center text-[7.1px] leading-[12.2px];
            }

            .graph {
              @apply relative h-[95.6px] w-full mt-[6.1px];

              &::before {
                @apply absolute left-0 top-0 h-0 w-0 border-b-[95.6px] border-b-white border-r-[15.9px] border-r-transparent content-[''] z-20;
              }

              &::after {
                @apply absolute right-0 top-0 h-0 w-0 border-b-[95.6px] border-b-white border-l-[15.9px] border-l-transparent content-[''] z-20;
              }

              .registered {
                @apply absolute bottom-0 left-0 right-0 justify-center h-full w-full bg-livestorm-blue-500 z-0;

                p {
                  @apply mt-[22px] font-inter font-medium text-[7.4px] text-white text-center leading-[12.2px];
                }
              }

              .attended {
                @apply absolute bottom-0 left-0 right-0 justify-center h-[43px] w-full  bg-livestorm-blue-700 z-10;

                p {
                  @apply mt-auto mb-[14.1px] font-inter font-medium text-[7.4px] text-white text-center leading-[12.2px];
                }
              }
            }
          }

          .progression {
            @apply flex-col items-center w-[512px];

            .title {
              @apply bg-grey-blue-100 px-[6px] font-inter font-medium text-grey-blue-900 text-center text-[7.1px] leading-4 rounded-[2px];
            }

            .graph {
              @apply relative h-full w-[512px] mt-3;

              .x-axis {
                @apply absolute bottom-0 left-[4.5px] justify-between w-[507.5px];

                p {
                  @apply font-inter text-grey-blue-700 text-[6px] leading-[8.1px];
                }
              }

              .y-axis {
                @apply absolute bottom-[8.6px] -left-[6.2px] flex-col items-end justify-between h-[90px] w-auto;

                p {
                  @apply font-inter text-grey-blue-700 text-[6px] leading-[8.1px];
                }
              }

              svg {
                @apply absolute bottom-[13.8px] left-[14.5px] h-[67px] w-[488px];
              }
            }
          }
        }
      }

      .fifth-step {
        &.apps {
          @apply absolute left-16 top-16 flex-col max-w-[712px];

          .title {
            @apply font-inter font-medium text-grey-blue-800 text-sm leading-6;
          }

          .table {
            @apply flex-col mt-2 bg-white border border-grey-blue-200 rounded-2xl;

            .head {
              @apply items-center justify-between h-10 pl-16 pr-10;

              p {
                @apply font-inter text-grey-blue-600 text-sm leading-6;
              }
            }

            .body {
              @apply flex-col;

              .row {
                @apply items-center pl-6 pr-14 py-4 border-t border-grey-blue-200;

                .logo {
                  @apply flex-shrink-0 rounded-none;
                }

                .logo + .name {
                  @apply ml-4;
                }

                .name {
                  @apply flex-shrink-0 ml-10 mr-auto font-inter text-grey-blue-800 text-sm leading-6;
                }

                .enabled {
                  @apply flex-shrink-0 items-center justify-center h-[22px] w-[22px] border border-grey-blue-400 rounded-[5px];

                  .icon {
                    @apply text-grey-blue-400 text-sm leading-none;
                  }
                }
              }
            }
          }
        }

        &.call-to-action {
          @apply absolute left-1/2 top-1/2 flex-col max-w-[465px] transform -translate-x-1/2 -translate-y-1/2 z-20;

          .h4 {
            @apply text-center;
          }

          .h4 + .button {
            @apply mt-6;
          }

          .button {
            @apply self-center w-auto;
          }

          .button + .button {
            @apply mt-4;
          }
        }

        &.filter {
          @apply absolute bottom-0 left-0 right-0 h-[302px] w-full bg-gradient-to-b from-transparent to-white backdrop-blur-[1px] z-10;
        }
      }
    }
  }
}
</style>
